<template>
  <div>
    <br />
    <br />
    <section
      class="container-fluid g-px-100 g-pt-50 g-pb-30"
      v-if="page.loading"
    >
      <v-progress-linear indeterminate color="cyan"></v-progress-linear>
    </section>

    <section
      class="container-fluid g-px-60--lg g-px-50--sm g-pt-50"
      v-if="!page.loading"
    >
      <div class="row">
        <div class="col-sm-6 col-lg-6 g-px-10">
          <div class="media g-mb-30">
            <div
              class="media-body u-shadow-v22 g-bg-secondary g-pa-30 g-rounded-15"
            >
              <div class="g-mb-40">
                <h3
                  class="d-flex justify-content-between align-items-center g-color-gray-dark-v4 mb-0"
                >
                  <span class="d-block g-mr-10"
                    >Hola
                    {{
                      currentUserInfo.userType ==
                      allConstants.security.userTypes.person
                        ? currentUserInfo.name
                        : currentUserInfo.surname
                    }}</span
                  >
                  <router-link
                    to="#"
                    v-on:click.native="gotoEditProfile"
                    class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover g-font-size-12"
                    >editar perfil <img src="assets/img/edit.svg"
                  /></router-link>
                </h3>
              </div>

              <div
                v-if="
                  currentUserInfo.userType ==
                    allConstants.security.userTypes.person
                "
              >
                <p>
                  <img src="assets/img/name.svg" />{{
                    currentUserInfo.visiblename.toUpperCase()
                  }}
                </p>
                <p>
                  <img src="assets/img/mail.svg" /> {{ currentUserInfo.email }}
                </p>
                <p>
                  <img src="assets/img/telephone.svg" /> +56 9
                  {{ currentUserInfo.cellphone }}
                </p>
              </div>
              <div v-else>
                <p>
                  <img src="assets/img/icon-factory.svg" />{{
                    currentUserInfo.name
                  }}
                  <img src="assets/img/rut.svg" class="ml-4" />{{
                    currentUserInfo.userName | formatRut
                  }}
                </p>
                <p>
                  <img src="assets/img/name.svg" />{{
                    currentUserInfo.surname.toUpperCase()
                  }}
                </p>
                <p>
                  <img src="assets/img/mail.svg" /> {{ currentUserInfo.email }}
                </p>
                <p>
                  <img src="assets/img/telephone.svg" />
                  <span>+56 9 {{ currentUserInfo.cellphone }}</span>
                  <span class="ml-3">+56 {{ currentUserInfo.phone }}</span>
                </p>
              </div>
              <hr class="g-my-10" style="border: 1px solid;" />
              <p><img src="assets/img/map.svg" /> {{ userFullAddress }}</p>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3 g-px-5">
          <div class="media g-mb-30">
            <figcaption class="g-pos-abs g-top-0 g-right-30">
              <img src="assets/img/chevron-green.svg" />
            </figcaption>
            <div
              class="media-body u-shadow-v22 g-bg-light-green g-pa-30 g-rounded-15"
            >
              <div class="g-mb-40">
                <h4
                  class="d-flex justify-content-between align-items-center h5 g-color-green mb-0"
                >
                  <span class="d-block g-mr-10 g-font-weight-700"
                    ><img src="assets/img/claims.svg" /> Reclamos</span
                  >
                </h4>
              </div>
              <ul class="list-inline d-sm-flex my-0 ">
                <li class="list-inline-item g-mr-20">
                  <p>Activos {{ activeClaims }}:</p>
                </li>
                <li class="list-inline-item ml-auto">
                  <router-link
                    to="#"
                    v-on:click.native="gotoActiveClaims"
                    class="u-link-v5 g-color-green g-color-primary--hover"
                  >
                    Ver estado
                  </router-link>
                </li>
              </ul>
              <ul class="list-inline d-sm-flex my-0">
                <li class="list-inline-item g-mr-20">
                  <p>Resueltos {{ solvedClaims }}:</p>
                </li>
                <li class="list-inline-item ml-auto">
                  <router-link
                    to="#"
                    v-on:click.native="gotoNotActiveClaims"
                    class="u-link-v5 g-color-green g-color-primary--hover"
                  >
                    Ver Resueltos
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="media g-mb-30">
            <div
              class="media-body u-shadow-v22 g-bg-light-green-link g-pa-20 g-rounded-15"
            >
              <router-link to="#" v-on:click.native="beginCreateNewClaim">
                <div class="row">
                  <div class="col-lg-6 h5 g-color-green">
                    Ingresar Nuevo Reclamo
                  </div>
                  <div class="col-lg-6">
                    <img src="assets/img/new-query.svg" />
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3 g-px-10" v-show="true">
          <div class="media g-mb-30">
            <figcaption class="g-pos-abs g-top-0 g-right-30">
              <img src="assets/img/chevron-yellow.svg" />
            </figcaption>
            <div
              class="media-body u-shadow-v22 g-bg-yellow-light g-pa-30 g-rounded-15"
            >
              <div class="g-mb-40">
                <h4
                  class="d-flex justify-content-between align-items-center h5 g-color-orange mb-0"
                >
                  <span class="d-block g-mr-10 g-font-weight-700"
                    ><img src="assets/img/inquiries.svg" /> Consultas</span
                  >
                </h4>
              </div>
              <ul class="list-inline d-sm-flex my-0">
                <li class="list-inline-item g-mr-20">
                  <p>Activos {{ activeQueries }}:</p>
                </li>
                <li class="list-inline-item ml-auto">
                  <router-link
                    to="#"
                    v-on:click.native="gotoActiveQueries"
                    class="u-link-v5 g-color-orange g-color-orange--hover"
                  >
                    Ver estado
                  </router-link>
                </li>
              </ul>
              <ul class="list-inline d-sm-flex my-0">
                <li class="list-inline-item g-mr-20">
                  <p>Resueltos {{ solvedQueries }}:</p>
                </li>
                <li class="list-inline-item ml-auto">
                  <router-link
                    to="#"
                    v-on:click.native="gotoNotActiveQueries"
                    class="u-link-v5 g-color-orange g-color-orange--hover"
                  >
                    Ver Resueltos
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="media g-mb-30">
            <div
              class="media-body u-shadow-v22 g-bg-yellow-light-link g-pa-20 g-rounded-15"
            >
              <router-link to="#" v-on:click.native="beginCreateNewQuery">
                <div class="row">
                  <div class="col-lg-6 h5 g-color-orange">
                    Ingresar Nueva Consulta
                  </div>
                  <div class="col-lg-6">
                    <img src="assets/img/new-query-y.svg" />
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimInCreationMixin from "@/mixins/currentClaimInCreation";
import currentQueryInCreationMixin from "@/mixins/currentQueryInCreation";
import currentUpdateProfileMixin from "@/mixins/currentUpdateProfile";

import claimsService from "@/services/claims";
import queriesService from "@/services/queries";
export default {
  mixins: [
    baseViewMixin,
    currentUserMixin,
    currentClaimInCreationMixin,
    currentUpdateProfileMixin,
    currentQueryInCreationMixin,
  ],
  data() {
    return {
      formFields: {},
      activeClaims: 0,
      solvedClaims: 0,
      activeQueries: 0,
      solvedQueries: 0,
      showDialogLogin: false,
    };
  },
  computed: {
    userFullAddress() {
      let fullAddress = this.currentUserInfo.address.streetAddress;

      if (this.currentUserInfo.address.apartment != "") {
        if (
          this.currentUserInfo.userType ==
          this.allConstants.security.userTypes.person
        ) {
          fullAddress =
            fullAddress + ", depto " + this.currentUserInfo.address.apartment;
        } else {
          fullAddress =
            fullAddress + ", oficina " + this.currentUserInfo.address.building;
        }
      }

      const allRegions = this.$Parameters.allParameters.find(
        (parameter) => parameter.type == "Regions"
      ).values;
      const region = allRegions.find(
        (region) => region.code == this.currentUserInfo.address.region
      );

      if (region != undefined) {
        const province = region.provinces.find(
          (province) => province.code == this.currentUserInfo.address.city
        );
        const commune = province.communes.find(
          (commune) => commune.code == this.currentUserInfo.address.commune
        );

        fullAddress = fullAddress + ", " + commune.name + ", " + province.name;
      } else {
        fullAddress = fullAddress + ", " + this.currentUserInfo.address.region;
      }

      return fullAddress;
    },
  },
  methods: {
    async beginCreateNewClaim() {
      const isRegisteredUser = true;
      const fromPublicSite = true;

      await this.startCurrentClaimInCreation(isRegisteredUser, fromPublicSite);

      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.userType = this.currentUserInfo.userType;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);

      this.replaceView("claimCreationFlow");
    },
    gotoActiveClaims() {
      this.gotoView("claims", {
        macroStatusesToInclude: this.allConstants.dashboard.groups.claimant
          .claimsActive.statuses,
      });
    },
    gotoNotActiveClaims() {
      this.gotoView("claims", {
        macroStatusesToInclude: this.allConstants.dashboard.groups.claimant
          .claimsSolved.statuses,
      });
    },

    async beginCreateNewQuery() {
      const isRegisteredUser = true;
      const fromPublicSite = true;

      await this.startCurrentQueryInCreation(isRegisteredUser, fromPublicSite);

      let currentQueryInCreation = this.currentQueryInCreation;
      currentQueryInCreation.userType = this.currentUserInfo.userType;

      await this.currentQueryInCreationCreateOrUpdate(currentQueryInCreation);

      this.replaceView("queryCreationFlow");
    },
    gotoActiveQueries() {
      this.gotoView("queries", {
        macroStatusesToInclude: this.allConstants.dashboard.groups.claimant
          .queriesActive.statuses,
      });
    },
    gotoNotActiveQueries() {
      this.gotoView("queries", {
        macroStatusesToInclude: this.allConstants.dashboard.groups.claimant
          .queriesSolved.statuses,
      });
    },

    gotoEditProfile() {
      this.startCurrentClaimantUpdateProfile();
      this.gotoView("userProfileEdit");
    },
  },
  async beforeMount() {
    this.initSignalLoading();

    const getResult = await claimsService.getByIdentification(
      this.currentUserInfo.userName
    );
    const userClaims = getResult.data;
    this.activeClaims = userClaims.filter((claim) =>
      this.allConstants.dashboard.groups.claimant.claimsActive.statuses.includes(
        claim.state
      )
    ).length;
    this.solvedClaims = userClaims.filter((claim) =>
      this.allConstants.dashboard.groups.claimant.claimsSolved.statuses.includes(
        claim.state
      )
    ).length;

    const getResultQuery = await queriesService.GetQueriesByClient(
      this.currentUserInfo.userName
    );
    const userQueries = getResultQuery.data;
    this.activeQueries = userQueries.filter((query) =>
      this.allConstants.dashboard.groups.claimant.queriesActive.statuses.includes(
        query.state
      )
    ).length;
    this.solvedQueries = userQueries.filter((query) =>
      this.allConstants.dashboard.groups.claimant.queriesSolved.statuses.includes(
        query.state
      )
    ).length;

    this.stopSignalLoading();
  },
};
</script>

<style lang="scss" scoped></style>
